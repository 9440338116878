import { CustomerRoutes } from '../types/routes'

export default defineNuxtRouteMiddleware(() => {
  const { localePath } = useT3Utils()
  const { customerHasSystemRole } = useCustomer()

  if (import.meta.server && !customerHasSystemRole.value) {
    return navigateTo({
      path: localePath(CustomerRoutes.ACCOUNT),
      query: {
        error: 'unauthorised',
      },
    })
  }
})
